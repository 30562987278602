export default class Data {
  constructor(
    raw,
    { id, timeCreated, timeModified, isCompleted, isSkipped } = {}
  ) {
    this.id = id || (`${Date.now()}-${Math.random()}` )
    this.raw = raw

    this.timeCreated = timeCreated || Date.now()
    this.timeModified = timeModified

    this.isCompleted = isCompleted
    this.isSkipped = isSkipped

    this.derivedFrom = undefined

    this.attributes = {

    }
  }
}
