export const dedupe = (list, key) =>
  list.filter(
    (elem, index, self) =>
      self.findIndex(t => {
        return t[key] === elem[key]
      }) === index
  )

export class Counter {
  constructor(items) {
    this.counts = new Map()
    this.update(items)
  }

  update(items) {
    for (let x of items) {
      this.counts.set(x, (this.counts.get(x) || 0) + 1)
    }
  }

  mostCommon() {
    return [...this.counts.entries()].sort((a, b) => b[1] - a[1])
  }
}

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )




export const hashText = text => {
  let hash = 0,
    i,
    chr
  if (text.length === 0) return hash
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}