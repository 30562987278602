import Data from './Data'
import {observable} from 'mobx'


export class Text extends Data {
  constructor(
    raw,
    { id, timeCreated, timeModified, isCompleted, isSkipped } = {}
  ) {
    super(raw, { id, timeCreated, timeModified, isCompleted, isSkipped })

    this.tokens = []
    this.tokenize()
  }

  tokenize() {
    const substrings = this.raw.split(' ')
    let start = 0
    this.tokens = substrings.map(s => {
      const t = new Token(s, this, start)
      start += s.length + 1  // FIXME
      return t
    })
  }
}

export class Token {
  constructor(raw, source, start) {
    this.raw = raw

    this.source = source
    this.start = start

    this.tags = []
  }

  toggleTag(tag) {
    console.log(tag)
    if (this.tags.find(t => t === tag)) {
      this.tags = this.tags.filter(t => t !== tag)
    } else {
      this.tags = [...this.tags, tag]
    }
  }
}



class Span {
  constructor() {
    this.doc = null
    this.start = null
    this.end = null

    this.tags = null
  }

  get text() {
    return this.doc.raw.slice(this.start, this.end)
  }
}
